import React from "react";
import styles from "./ChatMessage.module.scss";
import Markdown from "react-markdown";
import clsx from "clsx";

export type ChatMessageProps = {
  role: "user" | "assistant" | "code";
  text: string;
};

const AssistantMessage = ({ text }: { text: string }) => {
  return (
    <div className={styles.assistantMessage}>
      <Markdown className={styles.content} urlTransform={(url) => url}>
        {text}
      </Markdown>
    </div>
  );
};

const UserMessage = ({ text }: { text: string }) => {
  return <div className={styles.userMessage}>{text}</div>;
};

export const AssistantMessageSkeleton = () => {
  return (
    <div
      className={clsx(styles.assistantMessage, styles.assistantMessageSkeleton)}
    >
      <div className={styles.dots}>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
        <span className={styles.dot}></span>
      </div>
    </div>
  );
};

const ChatMessage = ({ role, text }: ChatMessageProps) => {
  switch (role) {
    case "user":
      return <UserMessage text={text} />;
    case "assistant":
      return <AssistantMessage text={text} />;
    default:
      return null;
  }
};

export default ChatMessage;
