"use client"; // Ensure this component runs on the client side

import React, { useState } from "react";
import styles from "./NavigationButtons.module.scss";
import NavigationPopper from "../NavigationPopper/NavigationPopper";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import clsx from "clsx";
import { MedicalStaff } from "@/lib/strapiApiTypes/medicalStaff";

type PopperType = "services" | "staff" | "information" | "search" | null;

type Props = {
  medicalStaffData: MedicalStaff[];
};

const NavigationButtons = (props: Props) => {
  const [popperType, setPopperType] = useState<PopperType>(null);
  const pathname = usePathname();

  const navigationListItems = [
    {
      label: "Pagina principală",
      type: "home",
      href: "/",
    },
    {
      label: "Servicii medicale",
      type: "services",
      href: "/specialitati-medicale",
    },
    {
      label: "Cadre medicale",
      type: "staff",
      href: "/cadre-medicale",
    },
    {
      label: "Noutăți",
      type: "noutati",
      href: "/noutati",
    },
    {
      label: "Despre noi",
      type: "about-us",
      href: "/despre-noi",
    },
  ] as { label: string; type: PopperType; href: string }[];

  const handleClick = (type: PopperType) => {
    setPopperType(type);
  };

  const handlePopperBlur = () => {
    setPopperType(null);
  };

  return (
    <>
      <ul className={styles.navigationList}>
        {navigationListItems.map((navItem) => {
          return (
            <li
              key={navItem.label}
              className={clsx(pathname === navItem.href && styles.active)}
            >
              <Link href={navItem.href} prefetch>
                {navItem.label}
              </Link>
            </li>
          );
        })}
      </ul>
      {/* <div className={styles.searchBtn} onClick={() => handleClick("search")}>
        <span className={styles.searchIconWrapper}>
          <Image src="/images/search.svg" alt="search icon" fill />
        </span>
      </div> */}
    </>
  );
};

export default NavigationButtons;
