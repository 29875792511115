import React from "react";
import { Fab } from "@mui/material";
import { BsChatRightText } from "react-icons/bs";

import variables from "@/styles/variables_export.module.scss";

interface ChatButtonProps {
  onClick: () => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ onClick }) => {
  return (
    <Fab
      color="primary"
      aria-label="chat"
      onClick={onClick}
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        zIndex: 1000,
        backgroundColor: variables.primaryColor,
        "&:hover": {
          backgroundColor: variables.primaryColor,
        },
      }}
    >
      <BsChatRightText size="35%" />
    </Fab>
  );
};

export default ChatButton;
