import React from "react";
import Image from "next/image";
import clsx from "clsx/lite";
import styles from "./Buttons.module.scss";

export const AnalysisResultsButton = () => {
  return (
    <a
      className={clsx(styles.navbarBtn, styles.analysisResultsBtn)}
      href="http://84.232.249.71/easyhospital/loginPatient.asp"
    >
      <span className={styles.imgWrapper}>
        <Image src="/images/analysis_results.svg" alt="about" fill />
      </span>
      <p className={styles.text}>Rezultate analize</p>
    </a>
  );
};

export default AnalysisResultsButton;
