"use client";

import React, { useState } from "react";
import ChatFloatingButton from "./ChatFloatingButton/ChatFloatingButton";
import ChatContainer from "./ChatContainer/ChatContainer";

const ChatWidget: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ChatFloatingButton onClick={handleOpen} />
      <ChatContainer open={open} onClose={handleClose} />
    </>
  );
};

export default ChatWidget;
