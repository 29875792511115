"use client";

import React, { useEffect, useState } from "react";
import {
  Dialog,
  IconButton,
  useMediaQuery,
  Slide,
  SwipeableDrawer,
  DialogProps,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { IoCloseOutline } from "react-icons/io5";
import { useTheme, Theme } from "@mui/material/styles";
import Chat from "../Chat/Chat";

import styles from "./ChatContainer.module.scss";
import variables from "@/styles/variables_export.module.scss";

interface ChatContainerProps {
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatContainer: React.FC<ChatContainerProps> = ({ open, onClose }) => {
  const theme = useTheme<Theme>();
  // const [viewportHeight, setViewportHeight] = useState<number | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [drawerHeight, setDrawerHeight] = useState("calc(100vh - 16px)"); // Default to full viewport height

  useEffect(() => {
    const updateHeight = () => {
      // Use visualViewport height when available
      const height = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;
      setDrawerHeight(`calc(${height}px - 16px)`);
    };

    // Add event listener for resizing (keyboard opening/closing triggers this)
    window.visualViewport?.addEventListener("resize", updateHeight);

    // Set initial height
    updateHeight();

    return () => {
      window.visualViewport?.removeEventListener("resize", updateHeight);
    };
  }, []);

  const chatBase = (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>
          <strong className={styles.assistantName}>Sofia</strong> - Asistent
          Virtual
        </p>
        <IconButton
          onClick={onClose}
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
            padding: 8,
          }}
        >
          <IoCloseOutline size={28} color={variables.textLight} />
        </IconButton>
      </div>
      <div className={styles.chatContainer}>
        <Chat />
      </div>
    </div>
  );

  if (isMobile) {
    // Use SwipeableDrawer for mobile devices
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
        onOpen={() => {}}
        PaperProps={{
          style: {
            height: drawerHeight,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
      >
        {chatBase}
      </SwipeableDrawer>
    );
  }

  // Use Dialog for larger screens
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          position: "fixed",
          bottom: 0,
          right: 16,
          margin: 0,
          width: 400,
          height: 880,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: "hidden",
        },
      }}
    >
      {chatBase}
    </Dialog>
  );
};

export default ChatContainer;
