import Link from "next/link";
import { FaArrowRightLong } from "react-icons/fa6";
import styles from "./NavigationDrawer.module.scss";
import { ComponentType } from "react";
import { usePathname } from "next/navigation";
import clsx from "clsx/lite";

type IconComponentProps = {
  className?: string;
};

type DrawerLinkProps = {
  href: string;
  icon: ComponentType<IconComponentProps>;
  arrow?: boolean;
  text: string;
};

const DrawerLink = (props: DrawerLinkProps) => {
  const { href } = props;
  const pathname = usePathname();

  return (
    <Link
      href={href}
      className={clsx(styles.sectionField, pathname === href && styles.active)}
    >
      <props.icon className={styles.icon} />
      <p className={styles.buttonText}>{props.text}</p>
      {props.arrow && <FaArrowRightLong className={styles.icon} />}
    </Link>
  );
};

export default DrawerLink;
