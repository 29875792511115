"use client";
import { Drawer } from "@mui/material";
import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import AdaptiveLocationDrawerLink from "./AdaptiveLocationDrawerLink";

import styles from "./AdaptiveLocationLink.module.scss";
import { IoCloseOutline } from "react-icons/io5";

type Props = {
  lng?: number;
  lat?: number;
  placeName?: string;
  placeId?: string;
  className?: string;
  style?: CSSProperties;
};

export const AdaptiveLocationLink = (props: PropsWithChildren<Props>) => {
  const {
    children,
    className,
    style,
    lat = Number(process.env.NEXT_PUBLIC_MAP_LAT_COORDINATE) ||
      45.10279414172936,
    lng = Number(process.env.NEXT_PUBLIC_MAP_LONG_COORDINATE) ||
      24.359129709529075,
    placeName = process.env.NEXT_PUBLIC_GOOGLE_PLACE_NAME || "Balneomedcenter",
    placeId = process.env.NEXT_PUBLIC_GOOGLE_PLACE_ID ||
      "0x474d47693e9656eb:0xbba219e007c17aec",
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // `https://www.google.com/maps/dir/45.0830155,24.3772524/BalneoMedCenter,+Bulevardul+Dem+Radulescu,+R%C3%A2mnicu+V%C3%A2lcea/@45.0833254,24.3659861,300m`;

  const googleMapsLink = `https://www.google.com/maps/dir/${lat},${lng}/${placeName}/@${lat},${lng},300m`;
  const wazeLink = `waze://?ll=${lat},${lng}&navigate=yes`;
  const appleMapsLink = `maps://?daddr=${lat},${lng}&dirflg=d`;

  useEffect(() => {
    if (!navigator) return;
    setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  }, []);

  if (isMobile) {
    return (
      <>
        <a
          onClick={() => setDrawerOpen(true)}
          className={className}
          style={{ cursor: "pointer", ...style }}
        >
          {children}
        </a>
        <Drawer
          anchor="bottom"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          PaperProps={{
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            backgroundColor: "red",
          }}
        >
          <div className={styles.drawerHeader}>
            <p>Alege aplicația</p>
            <div onClick={() => setDrawerOpen(false)}>
              <IoCloseOutline className={styles.closeDrawerButtonIcon} />
            </div>
          </div>
          <div className={styles.drawerLinksContainer}>
            <AdaptiveLocationDrawerLink
              text="Google Maps"
              href={googleMapsLink}
            />
            <AdaptiveLocationDrawerLink text="Waze" href={wazeLink} />
            {navigator && navigator.userAgent.includes("iPhone") && (
              <AdaptiveLocationDrawerLink
                text="Apple Maps"
                href={appleMapsLink}
              />
            )}
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <a
      href={googleMapsLink}
      target="_blank"
      className={className}
      style={style}
    >
      {children}
    </a>
  );
};

export default AdaptiveLocationLink;
